import SettingCurrentLocationButton, {
  LocationButton,
} from 'components/floor/location-bar/modal/button/SettingCurrentLocationButton';
import { ICONS } from 'constants/icons';
import { LOCAL_STORAGE_ORIGIN_LOCATION_KEY } from 'data/localStorageKey';
import useBookmark from 'hooks/bookmark/useBookmark';
import useShareButton from 'hooks/share/useShareButton';
import { useState } from 'react';
import useCustomFieldStore from 'store/customField';
import useTenantStore from 'store/tenant';
import { StorageLocation } from 'types/origin/originLocation.type';
import styles from './PopupInnerButtons.module.scss';

const PopupInnerButtons = () => {
  // state 카페쇼 용 상세보기
  // const [qrTenant, setQrTenant] = useState<CustomFields | null>(null);

  // store
  // const langStore = useLanguageStore(); /** 카페쇼 용 상세보기 */
  const tenantStore = useTenantStore();
  const customFieldStore = useCustomFieldStore();
  // state
  const [checkUrl, setCheckUrl] = useState<string>(customFieldStore?.customFields?.homePage.value || '');
  // hook
  const bookmarkManager = useBookmark();
  const shareButtonManager = useShareButton();

  const storageOriginLocation = localStorage.getItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY);
  const originLocation: StorageLocation = storageOriginLocation ? JSON.parse(storageOriginLocation) : '';
  const isCurrentLocation = originLocation?.id === tenantStore.currentTenant?.id;

  /** 카페쇼 용 상세보기 */
  // useEffect(() => {
  //   const filterFields = tenantStore.currentTenant?.tenantCustomFields.filter(
  //     (field: CustomFields) => field.customFieldId === tenantStore.QR_CUSTOM_FIELD_ID,
  //   );
  //   if (filterFields) {
  //     setQrTenant(filterFields[0]);
  //   }
  // }, [tenantStore]);

  /**
   * @desc  클릭 시 새탭에 상세보기 링크 열림
   */
  const onClickOpenLink = () => {
    window.open(checkUrl, '_blank');
  };

  /**
   * @desc url 유효성 검사
   * @param url
   * @returns
   */
  const isValidURL = (url: string) => {
    if (url.length < 1) return false;

    let formattedUrl = url;

    // 프로토콜이 없는 경우 https:// 추가
    if (!/^(http:\/\/|https:\/\/)/.test(url)) {
      formattedUrl = `https://${url}`;
    }

    // 상태 업데이트를 기존 값과 비교해 중복 업데이트 방지
    if (checkUrl !== formattedUrl) {
      setCheckUrl(formattedUrl);
    }

    return true;
  };

  return (
    <div className={styles.button_wrapper}>
      {/** 현재 위치 지정 */}
      {!isCurrentLocation && (
        <>
          <SettingCurrentLocationButton
            buttonType={LocationButton.POPUP_INNER}
            clickedTenant={tenantStore.currentTenant}
          />
          <div className={styles.divider} />
        </>
      )}
      {/** 즐겨찾기 */}
      <div className={styles.button} onClick={bookmarkManager.onClickBookmark}>
        <img src={bookmarkManager.isActiveBookmark ? ICONS.STAR : ICONS.STAR_EMPTY} alt='즐겨찾기 지정 버튼 이미지' />
        <span>{bookmarkManager.isActiveBookmark ? '저장 됨' : '즐겨찾기'}</span>
      </div>

      {/** 홈페이지 */}
      {customFieldStore?.customFields?.homePage &&
        customFieldStore?.customFields?.homePage.value.length > 0 &&
        isValidURL(customFieldStore.customFields.homePage.value) && (
          <>
            <div className={styles.divider} />
            <div className={styles.button} onClick={() => onClickOpenLink()}>
              <img src={ICONS.GLOBE} alt='홈페이지 버튼 이미지' />
              <span>홈페이지</span>
            </div>
          </>
        )}

      {/** 카페쇼 용 상세보기 */}
      {/* {qrTenant && isValidURL(qrTenant.content?.[langStore.currentLang]) && (
        <>
          <div className={styles.divider} />
          <div className={styles.button} onClick={() => onClickOpenLink(qrTenant.content?.[langStore.currentLang])}>
            <img src={ICONS.GLOBE} alt='상세보기 버튼 이미지' />
            <span>상세보기</span>
          </div>
        </>
      )} */}

      {/** 공유하기 */}
      <div className={styles.divider} />
      <div className={styles.button} onClick={shareButtonManager.onClickShare}>
        <img src={ICONS.SHARE} alt='공유하기 지정 버튼 이미지' />
        <div className={styles.twinkle_container}>
          <span className={styles.text}>공유하기</span>
          <div className={styles.twinkle} />
        </div>
      </div>
    </div>
  );
};
export default PopupInnerButtons;
