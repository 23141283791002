import { useAxios } from 'api/axios';
import { EndPoint } from 'data/endpoint';
import useCustomFieldStore from 'store/customField';
import useLanguageStore from 'store/languages';
import { CustomField } from 'types/tenant/customField.type';

const useCustomField = () => {
  const { api } = useAxios();
  // store
  const customFieldStore = useCustomFieldStore();
  const langStore = useLanguageStore();

  const getCustomField = async (poiContentId: string, pointId: string) => {
    const response = await api.get<CustomField>(EndPoint.customFields.replace(':poi-content-id', poiContentId), {
      params: {
        pointId,
        languageCode: langStore.currentLang.toUpperCase(),
      },
    });

    if (response.data) {
      customFieldStore.setCustomFields(response.data);
    }

    return response.data;

    // const data = {
    //   phone: '010-1111-2222',
    //   homePage: 'https://www.dabeeo.com',
    //   businessHours: '운영시간: 10시 - 22시 ',
    //   classification: '분류',
    //   product: '품목',
    //   owner: '제이팍',
    //   address: '서울시',
    //   email: 'dabeeo@dabeeo.com',
    //   snsLinkedIn: 'https://www.dabeeo.com',
    //   snsYoutube: 'https://www.dabeeo.com',
    //   snsFacebook: 'https://www.dabeeo.com',
    //   snsInstagram: 'https://www.dabeeo.com',
    //   snsKakao: 'https://www.dabeeo.com',
    //   snsBlog: 'https://www.dabeeo.com',
    // };
    //  customFieldStore.setCustomField(data);
  };

  return {
    getCustomField,
  };
};

export default useCustomField;
