import { CustomFieldType } from 'types/tenant/customField.type';
import { create } from 'zustand';

type CustomFieldStoreType = {
  customFields: CustomFieldType | null;
  setCustomFields: (customFields: CustomFieldType) => void;
  resetCustomFields: () => void;
};

const useCustomFieldStore = create<CustomFieldStoreType>(set => ({
  customFields: null,
  setCustomFields(customFields: CustomFieldType) {
    set(() => ({
      customFields,
    }));
  },
  resetCustomFields() {
    set(() => ({
      customFields: null,
    }));
  },
}));

export default useCustomFieldStore;
