import { t } from 'i18next';
import { useEffect } from 'react';
import useColorSetStore from 'store/colorSet';
import useCustomFieldStore from 'store/customField';
import useLanguageStore from 'store/languages';
import useTenantStore from 'store/tenant';
import { CustomFields } from 'types/tenant/tenant.type';
import styles from './DetailTenantCustomField.module.scss';

type Props = {
  tenantCustomFields: CustomFields[];
};

// order
const CUSTOM_FIELDS_ORDER: string[] = [
  'businessHours',
  'phone',
  'owner',
  'address',
  'email',
  'classification',
  'product',
  'homePage',
];

const DetailTenantCustomFields = ({ tenantCustomFields }: Props) => {
  // store
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();
  const colorSetStore = useColorSetStore();
  const customFieldStore = useCustomFieldStore();

  useEffect(() => {
    return () => {
      customFieldStore.resetCustomFields();
    };
  }, []);

  return (
    <div className={styles.details_wrapper}>
      {/* AS-IS */}
      {tenantCustomFields?.map(
        (field, index) =>
          field.customFieldId !== tenantStore.QR_CUSTOM_FIELD_ID && (
            <div key={index} className={styles.detail_wrapper}>
              <div
                className={styles.detail_key}
                style={{
                  color: colorSetStore.text.item,
                }}
              >
                <span>{field.title[langStore.currentLang]}</span>
              </div>
              <div
                className={styles.detail_value}
                style={{
                  color: colorSetStore.text.title,
                }}
              >
                {field.content[langStore.currentLang].includes('http') ? (
                  <a target='_blank' href={field.content[langStore.currentLang]} rel='noreferrer'>
                    {field.content[langStore.currentLang]}
                  </a>
                ) : (
                  field.content[langStore.currentLang] || '-'
                )}
              </div>
            </div>
          ),
      )}
      {customFieldStore.customFields &&
        CUSTOM_FIELDS_ORDER.map((fieldName: string, index: number) => {
          // customFieldsOrder 순서대로 순회하며 customFields 항목이 있을때 content로  정의한다.
          const customField = customFieldStore?.customFields?.[fieldName];
          // content 가 빈값이면 노출 X
          if (!customField || !customField?.value) return;

          // homepage 제외
          if (fieldName === 'homePage') return;

          return (
            <div key={index} className={styles.detail_wrapper}>
              <div
                className={styles.detail_key}
                style={{
                  color: colorSetStore.text.item,
                }}
              >
                <span> {customField.key || t(`popup.customField.${fieldName}`)}</span>
              </div>
              <div
                className={styles.detail_value}
                style={{
                  color: colorSetStore.text.title,
                }}
              >
                {fieldName === 'phone' ? (
                  // 전화 번호 일때
                  <a className={styles.phone} href={customField.value && `tel:${customField.value}`}>
                    {customField.value}
                  </a>
                ) : customField.value.includes('http') ? (
                  // 내용중에 url 있을 경우
                  <a target='_blank' href={customField.value} rel='noreferrer'>
                    {customField.value}
                  </a>
                ) : (
                  customField.value
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default DetailTenantCustomFields;
