import { t } from 'i18next';
import useColorSetStore from 'store/colorSet';
import useCustomFieldStore from 'store/customField';
import styles from './DetailTenantCustomField.module.scss';

// order
const CUSTOM_FIELDS_ORDER: string[] = [
  'snsYoutube',
  'snsInstagram',
  'snsFacebook',
  'snsLinkedIn',
  'snsBlog',
  'snsKakao',
];

const DetailTenantSnsFields = () => {
  // store
  const colorSetStore = useColorSetStore();
  const customFieldStore = useCustomFieldStore();

  return (
    <div className={styles.details_wrapper}>
      {customFieldStore?.customFields &&
        CUSTOM_FIELDS_ORDER.map((fieldName: string, index: number) => {
          // customFieldsOrder 순서대로 순회하며 customFields 항목이 있을때 content로  정의한다.
          const customField = customFieldStore?.customFields?.[fieldName];
          // content 가 빈값이면 노출 X
          if (!customField || !customField?.value) return;

          return (
            <div key={index} className={styles.detail_wrapper}>
              <div
                className={styles.detail_key}
                style={{
                  color: colorSetStore.text.item,
                }}
              >
                <span> {customField.key || t(`popup.customField.${fieldName}`)}</span>
              </div>
              <div
                className={styles.detail_value}
                style={{
                  color: colorSetStore.text.title,
                }}
              >
                {customField.value.includes('http') ? (
                  // 내용중에 url 있을 경우
                  <a target='_blank' href={customField.value} rel='noreferrer'>
                    {customField.value}
                  </a>
                ) : (
                  // 그외
                  customField.value
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default DetailTenantSnsFields;
